'use strict';

var $ = require('jquery').noConflict();

var methods = {

  init: function() {
      methods.setRandomTheme();
  },

  setRandomTheme: function() {
    var rando = Math.floor((Math.random() * 9) + 1);
    $('.theme').addClass('theme' + rando);
  }

};


methods.init();
